import React from 'react'
import PropTypes from 'prop-types'
import ErrorComponent from '@components/error'

class Error extends React.Component {
  static async getInitialProps(props) {
    const { res, err } = props

    let statusCode
    if (res && res.statusCode) ({ statusCode } = res)
    else if (err && err.statusCode) ({ statusCode } = err)
    return { statusCode }
  }

  render() {
    const { statusCode } = this.props
    return <ErrorComponent statusCode={statusCode || 500} />
  }
}

Error.propTypes = {
  statusCode: PropTypes.number,
}

export default Error
